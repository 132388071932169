import store from "@/store"
export function range(start, stop, step = 1){ return Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step) }
export const v4Regex = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
export function calculateValue(target) {
    if (target === undefined || target === null || target == 'bust' || target == 'miss') {
        return 0
    }
    const multiplierString = target[0]
    const number = target.substr(1)
    if (number == "bull") {
        const mult = target[0]
        if (mult == "d") {
            return 50
        } else {
            return 25 
        }
    } else if (multiplierString == "o" || multiplierString == "i") {
        return  parseInt(number)
    } else if (multiplierString == "d") {
        return  2 * parseInt(number)
    } else if (multiplierString == "t") {
        return  3 * parseInt(number)
    }
}
function getKeyWithEquals(indices,equals){
    let equal_key = [...indices]
    if(equals.length > 0)
        indices.forEach((index,i)=>
            equals.filter(equal_indices=>equal_indices.slice(1).includes(index))
                .forEach(equal_indices=>equal_key[i] = equal_indices[0]));

    return JSON.stringify(equal_key)
}
function findEquals(elements){
    let equals = []
    let equals_map = new Map()
    elements.forEach((element,i)=>{
        if(equals_map.has(element))
            equals_map.get(element).push(i)
        else
            equals_map.set(element,[i])
    })
    equals_map.forEach((indices,)=>{
        if(indices.length > 1)
            equals.push(indices)
    })
    return equals
}
function bestPairs(indices,distance_map,equals,ticks){
    var key = getKeyWithEquals(indices,equals)
    if(distance_map.has(key)){
        return distance_map.get(key)
    }
    ticks[0]++
    let max_min_distance = -1
    let max_min_pairs = []
    for(let i = 1;i < indices.length;i++){
        let pair = [indices[0],indices[i]]
        let inbwetween =indices.slice(1,i).concat(indices.slice(i+1))
        let [min_pairs,min_distance] = bestPairs(inbwetween,distance_map,equals,ticks)
        min_distance = min_distance + distance_map.get(pair.join(","))[1]
        if(min_distance > max_min_distance){
            max_min_pairs = [pair].concat(min_pairs)
            max_min_distance = min_distance
        }
    }
    distance_map.set(key,[max_min_pairs,max_min_distance])
    return [max_min_pairs,max_min_distance]
}
function calc_dist_rec(c1,c2,round,calc_dist){
    if(Array.isArray(c1) && Array.isArray(c2))
        return c1.reduce((a1, v1) => a1 + c2.reduce((a2,v2)=> a2 + calc_dist_rec(v1,v2,round,calc_dist),0),0)
    else
        return calc_dist(c1,c2,round)
}	
export function createPairsOfMaximumDistance(elements,calc_dist){
    let round = 0
    while(elements.length > 2)
    {
        if(elements.length % 2 == 1)
            elements.push(undefined)
        
        let equals = findEquals(elements)
        let distance_map = new Map()
        let indices = Array.from(elements,(_,i)=>i)
        indices.forEach((i1)=>indices.slice(i1+1).forEach(i2=>{
            distance_map.set([i1,i2].join(","),[[[i1,i2]],calc_dist_rec(elements[i1],elements[i2],round,calc_dist)])
        }));
        let ticks = [0]
        let [pairs,] = bestPairs(indices,distance_map,equals,ticks)
        //console.log("Ticks: " + ticks[0])
        
        elements = pairs.map(pair=>[elements[pair[0]],elements[pair[1]]])
        round++
    }
    if(round == 0 )
        return elements.length  == 2 ? [elements] : []
    else
        return  elements.flat(round - 1).filter(i=>i !== undefined)
}
export function somebodyWon(game) {
    return game && 
    (game.players.some(player => player && player.wins == game.wins) || 
    (game.allowtie && game.wins == 1 && game.players.every(player =>player && player.wins == 0.5)));
}
export function canEditId(id){
    return store.state.scopes.includes('api.game.w') || (id && store.state.editHash && id.startsWith(store.state.editHash.id))
}

import { mapState } from 'vuex'
import Api from '@/api.js'
var mixin = {
  methods: {
    canEditId: (id) => canEditId(id),
    getAbsoluteUrl(path){
        const route =  this.$router.resolve({ path});
        return new URL(route.href, window.location.origin + window.location.pathname).href;
    },
    addQueryParam(keyValue){
        return this.updateQueryParams(keyValue,[]);
    },
    createPairsOfMaximumDistance: createPairsOfMaximumDistance,
    somebodyWon: somebodyWon,
    updateQueryParams(adds,dels){
        const queryParams = { ...this.$route.query };
        if(adds)
            Object.keys(adds).forEach(name=>{
                queryParams[name] = adds[name]
            })
        if(dels)
            dels.forEach(del=>{
                if(del in queryParams)
                    delete queryParams[del];
            });
        
        return this.$router.replace({ query: queryParams });
    },
    removeQueryParam(name){
        return this.updateQueryParams({},[name]);
    },
    calculateValue: (hit) => calculateValue(hit),
    
    loadData(data){
        let _this = this
        Object.keys(data).forEach(function(key) {
            if (key in _this.$data) { 
                _this[key] = data[key];
            }
        });
    },
    loadGame(gameid){
        if (localStorage) {
            const data = JSON.parse(localStorage.getItem(gameid) || null);
            if (data){
                this.loadData(data)
                return true;
            }
        }
        return false
    },
    updateGame(gameid,update){
        if (localStorage){
            const existing =  JSON.parse(localStorage.getItem(gameid) || null);
            var save = {}
            if(existing)
                save = {
                    ...existing,
                    ...update
                };
            else
                save = update
            localStorage.setItem(gameid,  JSON.stringify(save))
        }
        return Api.saveGame(gameid,update)
    },
    getDateTime() {
        var now     = new Date(); 
        var year    = now.getFullYear();
        var month   = now.getMonth()+1; 
        var day     = now.getDate();
        var hour    = now.getHours();
        var minute  = now.getMinutes();
        var second  = now.getSeconds(); 
        if(month.toString().length == 1) {
             month = '0'+month;
        }
        if(day.toString().length == 1) {
             day = '0'+day;
        }   
        if(hour.toString().length == 1) {
             hour = '0'+hour;
        }
        if(minute.toString().length == 1) {
             minute = '0'+minute;
        }
        if(second.toString().length == 1) {
             second = '0'+second;
        }   
        var dateTime = year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second;   
         return dateTime;
    }
  },
  computed: {
    ...mapState({
        username: 'username',
        scopes: 'scopes',
        isLoggedIn: 'isLoggedIn',
        editHash: 'editHash'
    })
 }
};
export default mixin;
