import { createStore } from 'vuex'
import { getCookieValue, decodeJWT } from '@/auth'
import Api from '@/api'
import * as faceapi from '@vladmandic/face-api'

let token = localStorage.getItem("access-token")
let token_decoded = decodeJWT(token)
if (!token_decoded) {
  localStorage.removeItem("access-token")
}

let userscopes_cookie = getCookieValue('userscopes')
if (userscopes_cookie)
  userscopes_cookie = atob(userscopes_cookie).split(',')

let editHash_cookie = getCookieValue('edit_uuid')
if (editHash_cookie){
  editHash_cookie = JSON.parse(atob(editHash_cookie))
  let now_seconds = Date.now() / 1000
  if (editHash_cookie['created'] < (now_seconds - 60*60*24)){
    editHash_cookie = undefined
  }
}

let username_cookie = getCookieValue('username')
if (username_cookie)
  username_cookie = atob(username_cookie)

function handleTokenResponse(data) {
  const _atoken = data["token"]
  const _rtoken = data["refresh_token"]
  let _decoded_atoken = decodeJWT(_atoken)
  if (_decoded_atoken) {
    localStorage.setItem("access-token", _atoken)
    localStorage.setItem("refresh-token", _rtoken)
  }
  return _decoded_atoken
}

export default createStore({
  state: {
    scopes: token_decoded ? token_decoded["scopes"] : userscopes_cookie || [],
    username: token_decoded ? token_decoded["sub"] :  username_cookie,
    isLoggedIn: token !== undefined && token !== null,
    isMobile: window.innerWidth <= 480,
    editHash: editHash_cookie,
    faceapi: faceapi,
    faceapi_isReady: false,
  },
  getters: {
  },
  mutations: {
    loginSuccess(state, _decoded) {
      state.scopes = _decoded["scopes"];
      state.username = _decoded["sub"];
      state.isLoggedIn = true;
    },
    loginFailure(state) {
      state.scopes = userscopes_cookie || []
      state.username = username_cookie;
      state.isLoggedIn = false;
    },
    logout(state) {
      state.scopes = userscopes_cookie || []
      state.username = username_cookie;
      state.isLoggedIn = false;
    },
    switchIsMobile(state) {
      state.isMobile = !state.isMobile
    },
    faceApiIsReady(state){
      state.faceapi_isReady = true
    }
  },
  actions: {
    login({ commit }, data) {
      let login_request = null;
      if (data.mode == "credentials") {
        login_request = Api.login(data.username, data.password);
      }
      else if (data.mode == "certificate") {
        login_request = Api.certificate_login();
      }
      else {
        commit('loginFailure');
        return Promise.reject("no auth mode defined");
      }

      return login_request.then(
          _tokens => {
            let _decoded = handleTokenResponse(_tokens)
            if (_decoded) {
              commit('loginSuccess', _decoded);
              return Promise.resolve();
            }
            else {
              commit('loginFailure');
              return Promise.reject();
            }
          },
          error => {
            commit('loginFailure');
            return Promise.reject(error);
          }
        );
    },
    logout({ commit }) {
      Api.logout();
      localStorage.removeItem("access-token")
      localStorage.removeItem("refresh-token")
      commit('logout');
    },
    refreshTokenIfNeeded({ commit }) {
      let token = localStorage.getItem("refresh-token")
      let decoded = decodeJWT(token)
      if (!decoded) {
        console.log("no valid refresh token found - cannot do anything")
        localStorage.removeItem("access-token")
        localStorage.removeItem("refresh-token")
        commit('logout');
      } else {
        Api.refreshToken()
        .then(
          _tokens => {
            let _decoded = handleTokenResponse(_tokens)
            console.log("tokens refreshed")
            commit('loginSuccess', _decoded);
          },
          error => {
            console.error("refresh failed - clear tokens")
            localStorage.removeItem("access-token")
            localStorage.removeItem("refresh-token")
            commit('loginFailure');
            return Promise.reject(error);
          }
        );
      }
    },

    resize({ commit }) {
      let _isMobile = window.innerWidth <= 480
      if (this.state.isMobile != _isMobile)
        commit('switchIsMobile');
    },
  },
  modules: {
  }
})


