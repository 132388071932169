import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import shared from '@/shared.js';
import tooltip from "@/directives/tooltip.js";
import "@/assets/tooltip.css";

// Create the app and apply the mixin
createApp(App)
    .use(store)
    .use(router)
    .directive("tooltip", tooltip)
    .mixin(shared)
    .mount('#app')


window.addEventListener('resize', () => {
    store.dispatch("resize")
})
