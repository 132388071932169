<template>
  <router-view />
</template>

<script>
  import * as faceapi from '@vladmandic/face-api'
  import initImageSrc from "@/assets/init.jpg"
  export default {
    mounted() {
      this.$store.dispatch('refreshTokenIfNeeded');
      this.tokenRefresher = window.setInterval(() => {
        this.$store.dispatch('refreshTokenIfNeeded');
      }, 10 * 60 * 1000);
      if(localStorage.getItem("initFaceApi") == "true"){
        this.initFaceApi()
      }
    },
    methods: {
      async initFaceApi() {
          let modelPath = "model"
          await faceapi.nets.tinyFaceDetector.load(modelPath);
          await faceapi.nets.faceLandmark68TinyNet.loadFromUri(modelPath)
          await faceapi.nets.faceRecognitionNet.loadFromUri(modelPath)
          const initImage = await faceapi.fetchImage(initImageSrc)
          await faceapi.detectAllFaces(initImage, new faceapi.TinyFaceDetectorOptions())
              .withFaceLandmarks(true)
              .withFaceDescriptors();
          console.log("Init done")
          this.$store.commit("faceApiIsReady")
      }
    }
  }
</script>

<style>
  @import '@/assets/global.css' 
  
</style>
